@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html {
  @apply h-full bg-white;
}
body {
  @apply h-full dark:bg-gray-900;
}
.tooltip {
  @apply invisible absolute;
}
.has-tooltip:hover .tooltip {
  @apply visible z-50;
}
.firebase-emulator-warning {
  display: none;
}
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
